@media screen and (max-width: 400px) {
    #features {
        padding: 20px;
        width: 111%;
    }
    #about,
    #services,
    #testimonials,
    #team,
    #contact,
    #footer {
        width: 111%;
    }

    #portfolio {
        width: 110%;
    }
}
.image-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}
.App-logo {
    width: 100%;
    height: 100vh;
    max-width: 100%;
    max-height: 100%;
    margin-top: 20px;
    object-fit: cover;
}
@media (max-width: 768px) {
    .image-logo {
        width: 100%;
        height: auto;
    }

    .App-logo {
        width: 100%;
        height: auto;
        max-height: 100%;
    }
}
